import gem from 'assets/icons/steps-3-diamand.png';
import user from 'assets/icons/steps-4-user.png';
import users from 'assets/icons/users-light@3x.png';
import Steps from './steps/Steps';
import checkmarkGreen from '../../assets/icons/checkmark-green.svg';
import checkmark from '../../assets/icons/checkmark.svg';
import landmark from '../../assets/icons/landmark-light@3x.png';
import laptop from '../../assets/icons/laptop-light@3x.png';
import piggyBank from '../../assets/icons/piggy-bank-light@3x.png';
import rocket from '../../assets/icons/rocket-light@3x.png';
import shoppingCart from '../../assets/icons/shopping-cart-light@3x.png';
import clipboard from '../../assets/icons/steps-1-clipboard.png';
import pieChart from '../../assets/icons/steps-2-piechart.png';
import axios from 'axios';
import ButtonRed from 'components/common/button/ButtonRed';
import {Component} from 'react';
import {Analytics} from 'util/Analytics';
import landingImage from 'assets/images/LandingPage.png';
import welcomeImage from 'assets/images/co-workers@3x.png';
import benefitImage from 'assets/images/undraw_instat_analysis_ajld@3x.png';
import {Card, Col, Container, Row} from 'react-bootstrap';
import './LandingPage.scss';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import {getContactPrefilledUrl} from 'util/ContactUrlGenerator';
import { generateLinkOutURL } from 'util/LinkOutURLGenerator';


export default class LandingPage extends Component<ICategoriesProps, ICategoryDescriptionState> {
    
    constructor(props: any) {
        super(props);

        this.state = {
            categoryDescriptions: {} as any,
            consultantLink: getContactPrefilledUrl(),
        };

        this.loadModalCategoryDescription();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    loadModalCategoryDescription() {
        axios.get('/content/modal-category-description.json').then(res => {
            this.setState({categoryDescriptions: res.data});
        });
    }

    renderNextButton() {
        const questionnaireUuid = this.props.match.params.uuid;
        let nextButtonUrl = '/keyfacts';
        if (questionnaireUuid !== undefined) {
            nextButtonUrl = nextButtonUrl + '/' + questionnaireUuid;
        }

        return (
            <div className='next-button' onClick={Analytics.getInstance().onDigiCheckStart}>
                <ButtonRed url={nextButtonUrl} content='Jetzt Digitalisierungs-Check starten'/>
            </div>);
    }

    onClick() {
        Analytics.getInstance().onDigitizationAdviceButtonClicked();
        var generatedLink = generateLinkOutURL(getContactPrefilledUrl());
        this.setState({
          consultantLink : generatedLink
        });
    }

    render() {
        if (Object.entries(this.state.categoryDescriptions).length === 0 && this.state.categoryDescriptions.constructor === Object) {
            return null;
        }

        return (
            <div className='landingPage-container'>
                <div className='hero' style={{backgroundImage: `url(${landingImage})`}}>
                    <Container className='mb-5 pt-5'>
                        <Card className='welcome-wrapper col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-4'>
                            <Col className='pb-3'>
                                <Card.Body className='white'>
                                    <h1>Digitalisierungs-Check</h1>
                                    <h2>Ermitteln Sie Ihren digitalen Reifegrad in weniger als 15 Minuten.</h2>
                                    <ul style={{listStyleImage: `url(${checkmark})`}}>
                                        <li>Mit Sofortergebnis</li>
                                        <li>Mögliche Handlungsfelder und Hinweise zur Umsetzung</li>
                                        <li>Unverbindliche Beratung</li>
                                    </ul>
                                    <Row className='next-button-container'>
                                        <Col lg={7} style={{minWidth: 350}}>
                                            {this.renderNextButton()}
                                        </Col>
                                    </Row>
                                    <Row className='appointment-container'>
                                        <Col lg={7} style={{minWidth: 350}}>
                                            <a href={this.state.consultantLink} onClick={() => this.onClick()} className='button btn btn-danger outline full-width' target='_blank' rel='noopener noreferrer'>
                                                oder einen Beratungstermin vereinbaren
                                            </a>
                                        </Col>
                                        <Col lg={5} className='further-information'>
                                            <a
                                                href={'https://www.berliner-sparkasse.de/fi/home/branchen-und-berufe/digitalisierungsberatung.html?n=true&stref=search&q=Digitalisierungsberatung&mdidianlass=&mdidiansprache='}>Weitere
                                                Informationen</a>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Col>
                        </Card>
                    </Container>
                </div>
                <Container className='mt-5 mb-5'>
                    <div className='steps'>
                        <h3>Vorgehen</h3>
                        <div className='container-steps'>
                            <Steps title={'Fragebogen ausfüllen'} imgUrl={clipboard}/>
                            <Steps title={'Digitalisierungsgrad ermitteln'} imgUrl={pieChart}/>
                            <Steps title={'Handlungsoptionen finden'} imgUrl={gem}/>
                            <Steps title={'Digitalisierungsberater kontaktieren'} imgUrl={user}/>
                        </div>
                    </div>
                </Container>
                <div className='static-page-wrapper start-wrapper'>
                    <div style={{
                        backgroundColor: 'white',
                    }}>
                        <Container className='mt-5 mb-5'>
                            <Row style={{paddingTop: 20, paddingBottom: 20}} className='container-info'>
                                <Col className='align-items-center d-flex'>
                                    <div className='mb-8 align-middle' style={{top: '50%'}}>
                                        <h2>Ihr Digitaler Erfolg</h2>
                                        Mit dem kostenlosen Digitalisierungs-Check der Berliner Sparkasse können
                                        kleine und mittlere Unternehmen online Ihren individuellen Digitalisierungsgrad
                                        ermitteln.
                                        Finden Sie heraus, in welchen Bereichen Sie in Bezug auf Digitalisierung schon
                                        besonders gut
                                        aufgestellt sind und in welchen Bereichen noch Entwicklungsmöglichkeiten
                                        bestehen
                                        und wie diese durch Lösungsmaßnahmen unterstützt werden können.
                                    </div>
                                </Col>
                                <Col md={4} className='image-wrapper justify-content-center safari_only d-flex'>
                                    <Card.Img variant='top' src={welcomeImage}/>
                                </Col> </Row>
                        </Container>
                    </div>
                    <Container className='mt-5 mb-5'>
                        <div className='mb-4'>
                            <Row>
                                <Col md={12} className='text-right'>
                                    {this.renderNextButton()}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Container className='mt-5 mb-5 areas'>
                        <h2>Optimierung folgender Bereiche</h2>
                        <div className='container-areas'>
                            <div>
                                <div className='box' style={{
                                    backgroundImage: `url(${rocket})`,
                                }}>
                                    <h4>Kerngeschäft</h4>
                                    {this.state.categoryDescriptions['Kerngeschäft'].description}
                                </div>
                            </div>
                            <div className='box' style={{backgroundImage: `url(${landmark})`,
                            }}>
                                <h4>Online-Marketing <span className='box-title'>und -Vetrieb</span></h4>
                                {this.state.categoryDescriptions['Online-Marketing & -Vertrieb'].description}
                            </div>
                            <div>
                                <div className='box' style={{backgroundImage: `url(${users})`,
                                }}>
                                    <h4 className='box-title'>Digitales Personalmanagement</h4>
                                    <div className= 'truncate-text'>
                                        {this.state.categoryDescriptions['Digitales Personalmanagement'].description}
                                    </div>
                                </div>
                            </div>
                            <div className='box' style={{
                                backgroundImage: `url(${shoppingCart})`,
                            }}>
                                <h4>Digitale Verwaltung und Finanzprozesse</h4>
                                {this.state.categoryDescriptions['Digitale Verwaltung und Finanzprozesse'].description}
                            </div>
                            <div className='box' style={{
                                backgroundImage: `url(${piggyBank})`,
                            }}>
                                <h4>Digitale Warenwirtschaft</h4>
                                {this.state.categoryDescriptions['Digitale Warenwirtschaft'].description}
                            </div>

                            <div>
                                <div className='box' style={{
                                    backgroundImage: `url(${laptop})`,
                                }}>
                                    <h4>IT-Management</h4>
                                    {this.state.categoryDescriptions['IT-Management'].description}
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div style={{
                    backgroundColor: 'white',
                }}>
                    <Container className='mt-5 mb-5 benefits'>
                        <Row style={{alignItems: 'middle'}}>
                            <Col md={4} className='image-wrapper safari_only justify-content-center d-flex'>
                                <Card.Img variant='top' src={benefitImage}/>
                            </Col>
                            <Col>
                                <div className='mb-8'>
                                    <h2>Ihre Vorteile</h2>
                                    <ul style={{listStyleImage: `url(${checkmarkGreen})`}}>
                                        <li>Zugang zu neuen Technologien</li>
                                        <li>Entdecken bisher unbekannter Handlungsfelder</li>
                                        <li>Bereits mehrere tausend Kunden haben von unserer ausgezeichneten Beratung profitiert.</li>
                                        <li>Zukunftsorientierte Lösungen</li>
                                        <li>Unterstützung durch spezialisierten Berater möglich</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className='mt-5 mb-5'>
                    <Col md={12} className='text-right'>
                        {this.renderNextButton()}
                    </Col>
                </Container>
            </div>
        );
    }
}
